<template>
  <div data-app>
    <v-row>
      <v-col md="9" offset-xl="2" xl="6">
        <div class="card card-custom card-stretch">
          <!--begin::Body-->
          <div class="card-body">
            <v-stepper v-model="step" elevation="0">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1"
                  >Homepage</v-stepper-step
                >

                <v-divider></v-divider>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row>
                    <v-col md="12" xl="6">
                      <v-text-field
                        label="Titolo pagina"
                        v-model="homepage.title"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" xl="6">
                      <v-select
                        :items="headers"
                        label="Header"
                        v-model="homepage.page.header"
                      ></v-select>
                    </v-col>
                    <v-col md="6" xl="6">
                      <v-select
                        :items="headers"
                        v-model="homepage.page.mode"
                        label="Stile pagina"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="mb-0"><b>Contenuto</b></p>
                      <ckeditor
                        :editor="editor"
                        v-model="homepage.description"
                        @ready="onReady"
                      ></ckeditor>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module";
import {
  HOMEPAGES,
  CREATE_HOMEPAGE,
  UPDATE_HOMEPAGE,
} from "@/core/services/store/homepages.module";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@ckeditor/ckeditor5-build-decoupled-document/build/translations/it";
import { mapActions } from "vuex";

export default {
  name: "Homepage",
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      loading: false,
      headers: ["light", "dark"],
      homepage: {
        page: {
          header: "light",
          mode: "light",
        },
        id: null,
      },
      editor: DecoupledEditor,
      editorConfig: {
        language: "it",
      },
      step: 1,
    };
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   {
    //     title: "Homepage",
    //   },
    // ]);

    // --
    this.$nextTick(() => {
      this.getHomepage();
    });
  },
  created() {},
  computed: {},
  watch: {
    loading(newVal) {
      if (newVal) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      } else {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    getHomepage() {
      // INIT
      this.loading = true;

      this.$store
        .dispatch(HOMEPAGES)
        .then((data) => {
          if (data.homepage != null) {
            this.homepage = data.homepage;
            if (!this.homepage.page) {
              this.homepage.page = {
                header: "light",
                mode: "light",
              };
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>